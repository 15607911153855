// Overrides
@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Muli:400,700&display=swap");
}

.content blockquote {
  border-color: $primary;
}

.button {
  &.is-active,
  &:active {
    box-shadow: inset 1px 1px 4px rgba($grey-darker, 0.3);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.is-hovered,
      &:hover {
        background-color: darken($color, 10);
      }

      &.is-active,
      &:active {
        box-shadow: inset 1px 0 3px rgba($grey-darker, 0.3);
        background-color: darken($color, 10);
      }
    }
  }
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.progress {
  height: $size-small;
}

.progress,
.tag {
  border-radius: $radius;
}

.navbar {
  border-radius: $radius;

  .navbar-dropdown .navbar-item {
    @include desktop {
      color: $text;

      &.is-active {
        background-color: $navbar-dropdown-item-hover-background-color;
      }
    }
  }

  @include touch {
    .navbar-menu {
      background-color: inherit;
    }
  }

  .navbar-burger {
    span {
      background-color: $white;
    }
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      @include touch {
        .navbar-item,
        .navbar-link {
          color: $color-invert;
        }

        .navbar-burger {
          span {
            background-color: $color-invert;
          }
        }
      }
    }
  }
}

.hero {
  // Colors
  .navbar {
    background-color: $primary;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar {
        background: none;
      }
    }
  }
}

.menu {
  padding: 1em;
  background-color: lighten($primary, 50);

  .menu-list a:not(.is-active) {
    transition: all 300ms;

    &:hover {
      background-color: lighten($primary, 40);
    }
  }
}
